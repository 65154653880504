/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddOrEditUserModal from '../components/common/Modals/AddOrEditUserModal';
import DisableUserModal from '../components/common/Modals/DisableUserModal';
import ResetMfaModal from '../components/common/Modals/ResetMfaModal';
import ResetPasswordModal from '../components/common/Modals/ResetPasswordModal';
import { EDIT_USER } from '../constants/appVariables';
import { selectUsers } from '../redux/userAdmin/userAdmin.slice';
import { disableUser, editUser, resetUserMfa, resetUserPassword } from '../redux/userAdmin/userAdmin.thunks';
import IntlFormatterHOC from '../utils/intlFormatterHOC';
import telemetryEvent, { Events, Features } from './../utils/telemetry';

const BaseTableHOC = ComponentToWrap => {
  const HOCWrapper = ({ ...props }) => {
    const dispatch = useDispatch();

    const users = useSelector(selectUsers);

    const [disableUserModalOpen, setDisableUserModalOpen] = useState(false);
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const [resetMfaModalOpen, setResetMfaModalOpen] = useState(false);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const handleDisableUser = () => {
      const { id, email, name } = selectedUser;

      dispatch(disableUser({ userId: id, email, name }));

      setSelectedUser({});

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_DISABLE_USER);
    };

    const handleEditUser = (name, roles) => {
      const { id } = selectedUser;
      const selectedRolesObj = {};

      Object.entries(roles).forEach(([productKey, productDetails]) => {
        const selectedRolesArr = [];

        Object.entries(productDetails).forEach(([roleKey, roleDetails]) => {
          if (roleDetails.isSelected) {
            selectedRolesArr.push(roleKey);
          }
        });

        if (selectedRolesArr.length > 0) {
          selectedRolesObj[productKey || 'CommonRoles'] = selectedRolesArr;
        }
      });

      dispatch(editUser({ userid: id, name, roles: selectedRolesObj }));

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_EDIT_USER);
    };

    const handleResetMfa = () => {
      const { id, name } = selectedUser;

      dispatch(resetUserMfa({ userid: id, name }));

      setSelectedUser({});

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_RESET_USER_2FA);
    };

    const handleResetPassword = () => {
      const { id, name } = selectedUser;

      dispatch(resetUserPassword({ userid: id, name }));

      setSelectedUser({});

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_RESET_USER_PASSWORD);
    };

    const handleToggleDisableUserModal = userId => {
      const selectedUser = users.filter(({ id }) => id === userId)[0];

      setDisableUserModalOpen(!disableUserModalOpen);
      setSelectedUser(!disableUserModalOpen === false ? {} : selectedUser);

      telemetryEvent().menuItem().click().feature(Features.ADMIN).event(Events.TOGGLE_DISABLE_USER_MODAL);
    };

    const handleToggleEditUserModal = userId => {
      const selectedUser = users.filter(({ id }) => id === userId)[0];

      setEditUserModalOpen(!editUserModalOpen);
      setSelectedUser(!editUserModalOpen === false ? {} : selectedUser);

      telemetryEvent().menuItem().click().feature(Features.ADMIN).event(Events.TOGGLE_EDIT_USER_MODAL);
    };

    const handleToggleResetMfaModal = userId => {
      const selectedUser = users.filter(({ id }) => id === userId)[0];

      setResetMfaModalOpen(!resetMfaModalOpen);
      setSelectedUser(!resetMfaModalOpen === false ? {} : selectedUser);

      telemetryEvent().menuItem().click().feature(Features.ADMIN).event(Events.TOGGLE_RESET_MFA_MODAL);
    };

    const handleToggleResetPasswordModal = userId => {
      const selectedUser = users.filter(({ id }) => id === userId)[0];

      setResetPasswordModalOpen(!resetPasswordModalOpen);
      setSelectedUser(!resetPasswordModalOpen === false ? {} : selectedUser);

      telemetryEvent().menuItem().click().feature(Features.ADMIN).event(Events.TOGGLE_RESET_PASSWORD_MODAL);
    };

    return (
      <React.Fragment>
        <ComponentToWrap
          {...props}
          onToggleDisableUserModal={handleToggleDisableUserModal}
          onToggleEditUserModal={handleToggleEditUserModal}
          onToggleResetMfaModal={handleToggleResetMfaModal}
          onToggleResetPasswordModal={handleToggleResetPasswordModal}
        />
        {disableUserModalOpen && (
          <DisableUserModal
            isOpen={disableUserModalOpen}
            onSubmit={handleDisableUser}
            onToggleModal={handleToggleDisableUserModal}
            userName={selectedUser.name}
          />
        )}
        {editUserModalOpen && (
          <AddOrEditUserModal
            isOpen={editUserModalOpen}
            mode={EDIT_USER}
            onSubmit={handleEditUser}
            onToggleModal={handleToggleEditUserModal}
            userId={selectedUser.id}
          />
        )}
        {resetMfaModalOpen && (
          <ResetMfaModal
            isOpen={resetMfaModalOpen}
            onSubmit={handleResetMfa}
            onToggleModal={handleToggleResetMfaModal}
            userName={selectedUser.name}
          />
        )}
        {resetPasswordModalOpen && (
          <ResetPasswordModal
            isOpen={resetPasswordModalOpen}
            onSubmit={handleResetPassword}
            onToggleModal={handleToggleResetPasswordModal}
            userName={selectedUser.name}
          />
        )}
      </React.Fragment>
    );
  };

  HOCWrapper.propTypes = {
    onFormatIntl: PropTypes.func,
  };

  return IntlFormatterHOC(HOCWrapper);
};

BaseTableHOC.propTypes = {
  ComponentToWrap: PropTypes.node,
};

export default BaseTableHOC;
