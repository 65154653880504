import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { batch } from 'react-redux';

import {
  DEFAULT_ERROR_MESSAGE,
  ERROR,
  ROLE_AM_REGULAR_USER,
  ROLE_BM_REGULAR_USER,
  ROLE_PM_REGULAR_USER,
  SUCCESS,
  WARNING,
} from '../../constants/appVariables';
import { getTenantInfoQuery } from '../../graphql/queries/getTenantInfo';
import { getNumUsersByProduct, mapAndSortGroups, mapUserInfo } from '../../utils/user';
import { lmGraphQL } from '../../webAPIs/licenseManager';
import {
  deleteUserGroup,
  deleteUsersFromExistingUserGroup,
  getUserGroupsList,
  postAddUsersToExistingUserGroup,
  postCreateUserGroup,
  putRenameUserGroup,
} from '../../webAPIs/userCollaboration';
import {
  postClearUserMfa,
  postCreateUser,
  postDisableUser,
  postEditUser,
  postEditUserAccess,
  postEnableUser,
  postListUsers,
  postResendInvite,
  postResetPassword,
  postUnlockUser,
} from '../../webAPIs/users';
import { addNotification } from '../notifications/notifications.slice';
import { getMetricLimits } from './userAdmin.helpers';
import {
  deleteLoadingIndicator,
  setAddons,
  setAmPmPendingInvites,
  setBmActiveUsers,
  setBmPendingInvites,
  setTenantDomains,
  setUserGroups,
  setUsers,
} from './userAdmin.slice';

export const addUsersToExistingUserGroup = createAsyncThunk(
  'userAdmin/addUsersToExistingUserGroup',
  async ({ userGroupId, users }, thunkAPI) => {
    try {
      const res = await postAddUsersToExistingUserGroup(userGroupId, { users });

      if (res.status === SUCCESS) {
        await thunkAPI.dispatch(fetchUsers({}));

        thunkAPI.dispatch(
          addNotification({
            type: SUCCESS,
            message: 'User(s) have been added to user group.',
          })
        );
      } else {
        throw new Error();
      }
    } catch (err) {
      Sentry.captureException(err);

      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  }
);

export const createUser = createAsyncThunk('userAdmin/createUser', async ({ email, name, roles }, thunkAPI) => {
  try {
    const payload = {
      email,
      name,
      roles,
    };

    const res = await postCreateUser(payload);

    switch (res.status) {
      case SUCCESS:
        await thunkAPI.dispatch(fetchUsers({}));

        thunkAPI.dispatch(
          addNotification({
            type: SUCCESS,
            message: `${name} has been added.`,
          })
        );

        break;
      case ERROR: {
        const message = res.message;

        thunkAPI.dispatch(
          addNotification({
            type: ERROR,
            message: `${message}${message.slice(-1) === '.' ? '' : '.'}`,
          })
        );

        break;
      }
      case WARNING: {
        const message = res.message;

        await thunkAPI.dispatch(fetchUsers({}));

        thunkAPI.dispatch(
          addNotification({
            type: SUCCESS,
            message: `${name} has been added.`,
          })
        );
        thunkAPI.dispatch(
          addNotification({
            type: WARNING,
            message: `${message}${message.slice(-1) === '.' ? '' : '.'}`,
          })
        );

        break;
      }
      default:
        throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const createUserGroup = createAsyncThunk('userAdmin/createUserGroup', async ({ name, users }, thunkAPI) => {
  try {
    const res = await postCreateUserGroup({ name });

    if (res.status === SUCCESS) {
      if (users.length > 0) {
        await thunkAPI.dispatch(
          addUsersToExistingUserGroup({
            userGroupId: res.data.group_id,
            users,
          })
        );
      } else {
        await thunkAPI.dispatch(fetchUsers({}));
      }
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const disableUser = createAsyncThunk('userAdmin/disableUser', async ({ userId, email, name }, thunkAPI) => {
  try {
    const payload = {
      email,
      userId,
    };

    const res = await postDisableUser(payload);

    if (res.status === SUCCESS) {
      await thunkAPI.dispatch(fetchUsers({}));

      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: `${name} has been disabled.`,
        })
      );
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }

  thunkAPI.dispatch(deleteLoadingIndicator(userId));
});

export const editUser = createAsyncThunk('userAdmin/editUser', async ({ userid, name, roles }, thunkAPI) => {
  try {
    const editUserPayload = {
      name,
      userid,
    };

    const editUserAccessPayload = {
      roles,
      userid,
    };

    const selectedUser = thunkAPI.getState().userAdmin.users.filter(({ id }) => userid === id)[0];

    const isNameChange = selectedUser && selectedUser.name !== name;

    const rolesArr = Object.values(roles).reduce((a, b) => a.concat(b), []);

    const isRoleChange = selectedUser && JSON.stringify([...selectedUser.roles].sort()) !== JSON.stringify(rolesArr);

    let resEditUser = null;
    let resEditUserAccess = null;

    if (isNameChange && isRoleChange) {
      resEditUser = await postEditUser(editUserPayload);
      resEditUserAccess = await postEditUserAccess(editUserAccessPayload);
    } else if (isNameChange) {
      resEditUser = await postEditUser(editUserPayload);
    } else if (isRoleChange) {
      resEditUserAccess = await postEditUserAccess(editUserAccessPayload);
    }

    if (!selectedUser) {
      throw new Error();
    } else if (resEditUser && resEditUser.status === ERROR) {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: resEditUser.message }));
    } else if (resEditUserAccess && resEditUserAccess.status === ERROR) {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: resEditUserAccess.message }));
    } else {
      await thunkAPI.dispatch(fetchUsers({}));

      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: `${name} has been edited.`,
        })
      );
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const editUserGroup = createAsyncThunk(
  'userAdmin/editUserGroup',
  async ({ userGroupId, groupName, usersToAdd = [], usersToRemove = [] }, thunkAPI) => {
    try {
      const res = await putRenameUserGroup(userGroupId, { name: groupName });

      if (res.status === SUCCESS) {
        if (usersToAdd.length > 0) {
          await thunkAPI.dispatch(addUsersToExistingUserGroup({ userGroupId, users: usersToAdd }));
        }

        if (usersToRemove.length > 0) {
          thunkAPI.dispatch(
            removeUsersFromExistingUserGroup({
              userGroupId,
              users: usersToRemove,
            })
          );
        }

        if (usersToAdd.length === 0 && usersToRemove.length === 0) {
          await thunkAPI.dispatch(fetchUsers({}));
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      Sentry.captureException(err);

      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  }
);

export const enableUser = createAsyncThunk('userAdmin/enableUser', async ({ userid, name }, thunkAPI) => {
  try {
    const res = await postEnableUser({ userid });

    if (res.status === SUCCESS) {
      await thunkAPI.dispatch(fetchUsers({}));

      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: `${name} has been enabled.`,
        })
      );
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }

  thunkAPI.dispatch(deleteLoadingIndicator(userid));
});

export const fetchTenantInfo = createAsyncThunk('userAdmin/getTenantInfo', async (userpoolId, thunkAPI) => {
  try {
    const addons = {};
    const res = await lmGraphQL({
      query: getTenantInfoQuery(userpoolId),
    });

    res.data.getTenantInfo.Products.forEach(product => {
      if (product.AddOns) {
        addons[product.Type] = product.AddOns;
      }

      product.MetricLimits.forEach(metricLimit => {
        const metrics = getMetricLimits(metricLimit);

        if (metrics) {
          const { activationsType, licensesType, activations, licenses } = metrics;

          thunkAPI.dispatch(activationsType(activations));
          thunkAPI.dispatch(licensesType(licenses));
        }
      });
    });

    thunkAPI.dispatch(setAddons(addons));
    thunkAPI.dispatch(setTenantDomains(res.data.getTenantInfo.Domains));
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const fetchUsers = createAsyncThunk('userAdmin/fetchUsers', async ({ signal = null }, thunkAPI) => {
  try {
    await thunkAPI.dispatch(fetchTenantInfo(thunkAPI.getState().sessionUser.userpoolId));

    const resListUsers = await postListUsers(signal);
    const resListUserGroups = await getUserGroupsList('true', signal);

    if (resListUsers.status === SUCCESS && resListUserGroups.status === SUCCESS) {
      const groups = mapAndSortGroups(resListUserGroups.data);
      const users = resListUsers.data.users.map(user => mapUserInfo(user, groups));

      const amPmPendingInvites = getNumUsersByProduct(users, 'pending', [ROLE_AM_REGULAR_USER, ROLE_PM_REGULAR_USER]);
      const bmActiveUsers = getNumUsersByProduct(users, 'active', [ROLE_BM_REGULAR_USER]);
      const bmPendingInvites = getNumUsersByProduct(users, 'pending', [ROLE_BM_REGULAR_USER]);

      batch(() => {
        thunkAPI.dispatch(setAmPmPendingInvites(amPmPendingInvites));
        thunkAPI.dispatch(setBmActiveUsers(bmActiveUsers));
        thunkAPI.dispatch(setBmPendingInvites(bmPendingInvites));
        thunkAPI.dispatch(setUserGroups(groups));
        thunkAPI.dispatch(setUsers(users));
      });
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const removeUserGroup = createAsyncThunk(
  'userAdmin/removeUserGroup',
  async ({ userGroupId, users }, thunkAPI) => {
    try {
      if (users.length > 0) {
        await deleteUsersFromExistingUserGroup(userGroupId, { users });
        await deleteUserGroup(userGroupId);

        thunkAPI.dispatch(
          addNotification({
            type: SUCCESS,
            message: 'User group has been deleted.',
          })
        );

        await thunkAPI.dispatch(fetchUsers({}));
      } else {
        const res = await deleteUserGroup(userGroupId);

        if (res.status === SUCCESS) {
          await thunkAPI.dispatch(fetchUsers({}));
        } else if (res.status === ERROR) {
          const message = res.message;

          thunkAPI.dispatch(
            addNotification({
              type: ERROR,
              message: `${message}${message.slice(-1) === '.' ? '' : '.'}`,
            })
          );
        }
      }
    } catch (err) {
      Sentry.captureException(err);

      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  }
);

export const removeUsersFromExistingUserGroup = createAsyncThunk(
  'userAdmin/removeUsersFromExistingUserGroup',
  async ({ userGroupId, users }, thunkAPI) => {
    try {
      const res = await deleteUsersFromExistingUserGroup(userGroupId, {
        users,
      });

      if (res.status === SUCCESS) {
        await thunkAPI.dispatch(fetchUsers({}));
      } else {
        throw new Error();
      }
    } catch (err) {
      Sentry.captureException(err);

      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  }
);

export const resendInviteLink = createAsyncThunk(
  'userAdmin/handleResendInviteLink',
  async ({ userid, email, name }, thunkAPI) => {
    try {
      const res = await postResendInvite({ userid, email });

      if (res.status === SUCCESS) {
        thunkAPI.dispatch(
          addNotification({
            type: SUCCESS,
            message: `Invitation link for ${name} has been resent.`,
          })
        );
      } else {
        throw new Error();
      }
    } catch (err) {
      Sentry.captureException(err);

      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }

    thunkAPI.dispatch(deleteLoadingIndicator(userid));
  }
);

export const resetUserMfa = createAsyncThunk('userAdmin/resetUserMfa', async ({ userid, name }, thunkAPI) => {
  try {
    const res = await postClearUserMfa({ userid });

    if (res.status === SUCCESS) {
      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: `2FA reset for ${name} is complete.`,
        })
      );
    } else if (res.status === ERROR) {
      const message = res.message;

      thunkAPI.dispatch(
        addNotification({
          type: ERROR,
          message: `${message}${message.slice(-1) === '.' ? '' : '.'}`,
        })
      );
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const resetUserPassword = createAsyncThunk('userAdmin/resetUserPassword', async ({ userid, name }, thunkAPI) => {
  try {
    const res = await postResetPassword({ userid });

    if (res.status === SUCCESS) {
      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: `Password reset for ${name} complete.`,
        })
      );
    } else if (res.status === ERROR) {
      const message = res.message;

      thunkAPI.dispatch(
        addNotification({
          type: ERROR,
          message: `${message}${message.slice(-1) === '.' ? '' : '.'}`,
        })
      );
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const unlockUser = createAsyncThunk('userAdmin/unlockUser', async ({ userid, name }, thunkAPI) => {
  try {
    const res = await postUnlockUser({ userid });

    if (res.status === SUCCESS) {
      await thunkAPI.dispatch(fetchUsers({}));

      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: `${name} has been unlocked.`,
        })
      );
    } else {
      throw new Error();
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }

  thunkAPI.dispatch(deleteLoadingIndicator(userid));
});
